import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import AboutBrands from "../components/AboutBrands/AboutBrands";
import Description from "../components/Description/Description";

import aboutVideoHevc from "../videos/about/about-h265.mp4";
import aboutVideoWebm from "../videos/about/about-vp9.webm";
import aboutVideoMp4 from "../videos/about/about-h264.mp4";
import aboutPoster from "../videos/about/about-poster.jpg";

const About = () => (
  <Layout pageUrl="about">
    <SEO title="About" />
    <Hero
      home={false}
      pageTitle="About"
      videoSrcHevc={aboutVideoHevc}
      videoSrcWebm={aboutVideoWebm}
      videoSrcMp4={aboutVideoMp4}
      videoPoster={aboutPoster}
    />
    <Description
      heading="Un nome, uno studio, una squadra di professionisti"
      description="Parallel Milano è uno studio fotografico con sede a Milano e a Lugano specializzato in servizi per il mondo della moda. Il nostro team è formato da fotografi, video-maker, stylist, makeup artist, hair stylist, set-designer, post produttori e studio managers: un gruppo di professionisti pronto a sostenere brand e clienti nelle nuove sfide imposte dallo sviluppo del digital. Realizziamo scatti per e-commerce, lookbook, video e progetti speciali per i social media, garantendo flessibilità e massima attenzione ai dettagli e gestendo grandi flussi produttivi in tempi brevi."
    />
    <AboutBrands brandTitle="Lavoriamo per" />
  </Layout>
);

export default About;
